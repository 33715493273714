<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
      <v-form ref="editPersonalForm">
        <app-form-field
          :enabledLabel="true"
          v-model.trim="personalInfo.email.value"
          :label="$t(`field.email`)"
          :placeholder="$t(`fieldHint.fillUpHere`)"
          :rules="validator.emailRules()"
        ></app-form-field>
        <v-row no-gutters class="pb-10">
          <v-col cols="12">
            <app-button class="dialog-button" :action="this.submit" :title="$t(`button.submit`)"></app-button>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import stingFormat from 'string-format'
import { formValidator, uiHelper, errorCodeHelper, locale } from '@/util'
import { SHARED } from '@/constants/constants'
import { MEMBER_UPDATE_DETAIL } from '@/store/member.module'
export default {
  name: 'profileEditEmail',
  mounted() {
    this.$refs.editPersonalForm.reset()
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {}
    },
    personalInfo: {
      type: Object,
      require: true,
      default: {}
    }
  },
  data: () => ({
    dateOfBirthMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10),
    dateOfBirthMenu: false,
    language: uiHelper.getLanguage(),
    validator: formValidator,
    stingFormat: stingFormat,
    memberInfo: {},
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      }
    ]
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    mobilePrefix() {
      let self = this
      let prefix = _.find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.personalInfo.currency
      })
      if (prefix) return prefix.text
      else return ''
    }
  },
  watch: {
    dateOfBirthMenu(val) {
      val && setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = 'YEAR'))
    }
  },
  methods: {
    submit() {
      if (this.$refs.editPersonalForm.validate()) {
        this.confirmationDialog()
      }
    },
    updateMember() {
      let memberObj = this.personalInfo
      this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, { memberObj })
      this.closePageDialog()
      this.closeAction()
    },
    confirmationDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.updateMemberDetail`)
      dialog.message.push(locale.getMessage(`message.confirmUpdateProfile`))
      dialog.button.push({
        title: locale.getMessage(`button.submit`),
        action: () => this.updateMember()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    }
  }
}
</script>

<style lang="scss"></style>
