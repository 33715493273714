<template>
  <div class="background-linear">
    <v-row no-gutters align="end" class="px-8 pa-4 py-1 pt-5">
      <v-col md="9" lg="10" v-if="memberInfo.email.value">
        <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.email.value" :label="$t(`field.email`)"></v-text-field>
      </v-col>
      <v-col md="8" lg="8" v-else class="d-flex align-center justify-center">
        <v-col cols="10" class="pa-0">
          <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.email.value" :label="$t(`field.email`)"></v-text-field>
        </v-col>
        <v-col cols="2" class="pa-0 d-flex align-center justify-center">
          <v-btn @click="openEditDialog()" color="primary">Edit</v-btn>
        </v-col>
      </v-col>
    </v-row>
    <v-row no-gutters align="end" class="px-8 pa-4 py-1">
      <v-col md="9" lg="10">
        <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.memberCode" :label="$t(`field.username`)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters align="end" class="px-8 pa-4 py-1">
      <v-col md="9" lg="10">
        <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.dateOfBirth" :label="$t(`field.dateOfBirth`)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters align="end" class="  py-1" v-for="(n, j) in memberInfo.mobileList" :index="j" :class="j >= 1 ? 'SubPhoneCSS' : 'px-8 pa-4'">
      <v-col v-if="j == 0" md="9" lg="10">
        <v-text-field outlined :class="'mt-2'" readonly hide-details="auto" v-model.trim="n.value" :label="$t(`field.phoneNumber`)" @click:append="alert('123')" :prefix="mobilePrefix">
          <template v-slot:append>
            <v-icon v-if="n.is_verified" color="success">done</v-icon>
            <v-icon v-else color="warning" @click="openOTPDialog(j)">info</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col v-else md="9" lg="10">
        <v-text-field
          outlined
          :class="'mt-2'"
          readonly
          hide-details="auto"
          v-model.trim="n.value"
          :label="j < 2 ? $t(`field.2ndPhoneNumber`) : $t(`field.3rdPhoneNumber`)"
          @click:append="alert('123')"
          :prefix="mobilePrefix"
        >
          <template v-slot:append>
            <v-icon v-if="n.is_verified" color="success">done</v-icon>
            <v-icon v-else color="warning" @click="openOTPDialog(j)">info</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="memberInfo.mobileList.length < 2" class="px-8">
      <v-col cols="12" class="pt-0">
        <v-btn rounded color="newMobilePrimary" style="color: #FFFFFF" small @click="verifyPrimaryNumber()">
          {{ memberInfo.mobileList.length == 1 ? 'Add Secondary Number' : 'Add Tertiary Number' }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- 2ndary mobile -->
    <!--    <v-row no-gutters align="end" class="px-8 pa-4 py-1" v-if="memberInfo.secondaryMobile.value">-->
    <!--      <v-col md="9" lg="10">-->
    <!--        <v-text-field-->
    <!--          outlined-->
    <!--          :class="'mt-2'"-->
    <!--          disabled-->
    <!--          hide-details="auto"-->
    <!--          v-model.trim="memberInfo.secondaryMobile.value"-->
    <!--          :label="$t(`field.2ndPhoneNumber`)"-->
    <!--          @click:append="memberInfo.mobile.is_verified ? '' : openOTPDialog(1)"-->
    <!--        >-->
    <!--          <template v-slot:append>-->
    <!--            <v-icon v-if="memberInfo.mobile.is_verified" color="success">done</v-icon>-->
    <!--            <v-icon v-else color="warning">info</v-icon>-->
    <!--          </template>-->
    <!--        </v-text-field>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row no-gutters align="end" class="px-8 pa-4 py-1">
      <v-col md="9" lg="10">
        <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.name" :label="$t(`field.fullname`)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters align="end" class="px-8 pa-4 py-1 pb-5">
      <v-col md="9" lg="10">
        <v-text-field outlined :class="'mt-2'" hide-details="auto" disabled v-model.trim="memberInfo.currency" :label="$t(`field.currency`)"></v-text-field>
      </v-col>
    </v-row>
    <!--<v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters align="center" class="px-8">
        <v-col cols="12" class="text-right">
          <p class="subtitle-2">
            {{ $t(`message.profileBtmMsg_1`) }}
            <span class="profile-live-chat" @click="openLiveChat()">{{ $t(`label.liveChat`) }}</span>
            {{ $t(`message.profileBtmMsg_2`) }}
          </p>
        </v-col>
      </v-row>-->

    <!--<v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters align="center">
      <v-col cols="12" class="text-left mobile-subtitle ma-2">
        &lt;!&ndash;        <p class="subtitle-2">&ndash;&gt;
        {{ $t(`message.profileBtmMsg_1`) }}
        <span class="profile-live-chat" @click="openLiveChat()">{{ $t(`label.liveChat`) }}</span>
        {{ $t(`message.profileBtmMsg_2`) }}
        &lt;!&ndash;        </p>&ndash;&gt;
      </v-col>
    </v-row>-->

    <!-- EDIT DIALOG -->
    <!--    <app-dialog-->
    <!--      :dialogShow="editDialogShow"-->
    <!--      ref="personalEditDialog"-->
    <!--      :max-width="400"-->
    <!--      :title="$t(`label.editProfile`)"-->
    <!--      :isAuth="false"-->
    <!--      :header_title="'editProfile'"-->
    <!--      :closeAction="this.closeEditDialog"-->
    <!--    >-->
    <!--      <app-personal-edit :personalInfo="personalInfo" :closeAction="this.closeEditDialog"></app-personal-edit>-->
    <!--    </app-dialog>-->
    <app-dialog
      :dialogShow="editDialogShow"
      ref="personalEditDialog"
      :max-width="400"
      :title="$t(`label.editProfile`)"
      :isAuth="false"
      :header_title="'editProfile'"
      :closeAction="this.closeEditDialog"
    >
      <app-personal-edit-email :personalInfo="personalInfo" :closeAction="this.closeEditDialog"></app-personal-edit-email>
    </app-dialog>
    <app-dialog :title="$t(`label.addNewNumber`)" :dialog-show="addNewNumberDialog" ref="addNewNumberOtpDialog" :closeAction="this.closeAddNumberDialog" :max-width="400">
      <v-form ref="addNumberForm">
        <template>
          <app-form-field
            :enabledLabel="true"
            v-model.trim="newContactNumber.mobile"
            :label="$t(`field.phoneNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
            :prefix="mobilePrefix"
          ></app-form-field>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button class="white--text" :custom-class="'newMobilePrimary otpSubmitButton'" :action="this.AddNewSubContact" :title="$t(`button.submit`)"></app-button>
          </v-row>
        </template>
      </v-form>
    </app-dialog>
    <app-dialog :dialogShow="otpDialogShow" ref="personalOTPDialog" :closeAction="this.closeOTPDialog" :max-width="400" :title="$t(`label.verifyPhoneNo`)">
      <v-form ref="otpForm">
        <template>
          <captcha ref="captchaCode" v-model.trim="captcha" :isOptional="true" :errorMsg="captchaErrorMsg" :rules="validator.captchaRules()"></captcha>
          <app-form-field
            :enabledLabel="true"
            :value="mobilePrefix + ' ' + newContactNumber.mobile"
            :label="$t(`field.phoneNumber`)"
            :readonly="!newContactNumber.is_new_number"
            class="otpPhoneNo pb-2"
            :rules="validator.mobileNumberRules(defaultLanguage)"
          ></app-form-field>
          <v-row no-gutters class="pb-5">
            <v-col cols="5">
              <div class="mr-2">
                <v-btn
                  height="auto"
                  :disabled="this.$store.state.member.smsVerificationSent.countDownTimer > 0"
                  @click="this.requestSmsVerificationCode"
                  color="black"
                  depressed
                  class="otp-button newMobilePrimary font-weight-bold white--text mr-2"
                >
                  {{ this.$store.state.member.smsVerificationSent.countDownTimer > 0 ? formatSeconds(this.$store.state.member.smsVerificationSent.countDownTimer) : $t(`button.requestOtp`) }}
                </v-btn>
              </div>
            </v-col>
            <v-col>
              <app-form-field :enabledLabel="false" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" v-model.trim="verificationCode" type="text"></app-form-field>
            </v-col>
            <v-col cols="12">
              <label v-if="showText" class="ma-0 text-capitalize d-block pb-2 otp-text">
                <span class="red--text ml-1">*</span>
                {{ $t(`field.requestOtpText`) }}
              </label>
            </v-col>
          </v-row>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button
              class="white--text"
              :disabled="!verificationCode"
              :custom-class="'newMobilePrimary otpSubmitButton'"
              :action="newContactNumber.is_new_number ? this.verifyAndAddNewContact : this.verifySmsCode"
              :title="$t(`button.submit`)"
            ></app-button>
          </v-row>
        </template>
      </v-form>
      <!-- <v-row no-gutters>
           <v-col>
             <label>Phone Number</label>
           </v-col>
            <v-col>

              </v-col>

          </v-row> -->
    </app-dialog>
    <app-dialog :dialogShow="otpResultDialogShow" ref="otpResultDialog" :closeAction="this.closeOtpResultDialog" :max-width="350" :title="$t(`button.requestOtp`)">
      <label>{{ sendOTPAPIResult }}</label>
    </app-dialog>
  </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import {
  MEMBER_DETAIL,
  MEMBER_UPDATE_DETAIL,
  MEMBER_RESET_UPDATE_DETAIL_STORE,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_REST_OTPRESULT,
  MEMBER_PHONE_VERIFICATION_STATUS,
  MEMBER_2ND_PHONE_VERIFICATION_STATUS,
  MEMBER_RESET_SMS_VERIFICATION,
  MEMBER_ADD_NEW_CONTACT,
  MEMBER_RESET_ADD_NEW_CONTACT
} from '@/store/member.module'
import { LOOKUP_COUNTRY, LOOKUP_PROVINCE } from '@/store/lookup.module'
import AccountEdit from '@/assets/svg/profile/edit.svg'
import AppPersonalEdit from '@/components/member/personalInfoEdit.vue'
import AppPersonalEditEmail from '@/components/member/editEmail.vue'
import _ from 'lodash'
import Captcha from '@/components/Captcha.vue'

export default {
  name: 'myProfile',
  components: {
    Captcha,
    AccountEdit,
    AppPersonalEdit,
    AppPersonalEditEmail
  },
  data: () => ({
    is_add_new_number: false,
    addNewNumberDialog: false,
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    newContactNumber: {
      mobile: '',
      is_new_number: false
    },
    otpDialogShow: false,
    phonePriority: 1,
    verificationCode: '',
    otpResultDialogShow: false,
    language: uiHelper.getLanguage(),
    validator: formValidator,
    dateOfBirthMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10),
    dateOfBirthMenu: false,
    shared: SHARED,
    isPersonalProfileComplete: false,
    tempMemberInfo: {
      email: '',
      name: ''
    },
    profileTab: [
      {
        key: 'account',
        link: ROUTE_NAME.PROFILE
      },
      {
        key: 'personal',
        link: ROUTE_NAME.PERSONAL
      }
    ],
    personalInfoObj: {
      gender: '',
      dateOfBirth: '',
      country: '',
      province: '',
      postcode: '',
      nationality: '',
      address: ''
    },
    showText: false,
    editDialogShow: false,
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      }
    ],
    personalInfo: {
      memberCode: '',
      dateOfBirth: '',
      mobile: {
        value: '12345678909'
      },
      email: {},
      name: '',
      currency: '',
      secondaryMobile: {
        value: ''
      }
    },
    sendOTPAPIResult: '',
    captcha: '',
    captchaErrorMsg: undefined
  }),
  created() {
    this.getMemberDetail()
    this.getCountry()
  },
  computed: {
    addNewContactNumberResponse() {
      return this.$store.state.member.addNewNumberContact.complete
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.loaded
    },
    cPhonePriority() {
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
        return mobileList[arrIndex].value
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    updateDetailResponseComplete() {
      return this.$store.state.member.updateDetailResponse.complete
    },
    country() {
      return this.$store.state.lookup.country
    },
    province() {
      return this.$store.state.lookup.province
    },
    mobilePrefix() {
      let self = this
      let prefix = _.find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.memberInfo.currency
      })
      if (prefix) return prefix.text
      else return ''
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.code
    }
  },
  watch: {
    addNewContactNumberResponse() {
      if (this.$store.state.member.addNewNumberContact.complete) {
        if (this.$store.state.member.addNewNumberContact.success) {
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false
          this.addNewNumberDialog = false
          let index = this.phonePriority
          this.getMemberDetail()
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)
        } else {
          this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(this.$store.state.member.addNewNumberContact.code))
        }
        this.$store.dispatch(`${MEMBER_RESET_ADD_NEW_CONTACT}`)
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
        this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      }
    },
    smsVerificationResult() {
      let response = this.$store.state.member.smsVerificationResult
      //show alert on repsonse //loaded =true : back from server with response
      if (response.loaded) {
        if (response.success) {
          //if reponse=phone verified show alert and close while otp window
          this.$parent.openAlert(true, 'Phone verified successfully')
          this.otpDialogShow = false

          // if (this.phonePriority == 1) {
          //   this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`)
          // } else if (this.phonePriority == 2) {
          //   this.$store.dispatch(`${MEMBER_2ND_PHONE_VERIFICATION_STATUS}`)
          // }
          if (this.is_add_new_number) {
            this.newContactNumber.is_new_number = true
            this.newContactNumber.mobile = ''
            this.addNewNumberDialog = true
            this.is_add_new_number = false
          }
          let index = this.phonePriority
          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`, index)

          this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
          this.verificationCode = ''
        } else {
          if (response.code == -1) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
          } else if (response.code == -3) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.tooManyRequest'))
          } else {
            this.$parent.openAlert(response.success, `Failed to verify (${response.message}). please try again`)
          }
        }
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
      }
    },
    memberInfo() {
      let info = this.$store.state.member.info
      this.personalInfo = {
        memberCode: info.memberCode,
        dateOfBirth: info.dateOfBirth,
        mobile: info.mobile,
        name: info.name,
        currency: info.currency,
        email: info.email,
        secondaryMobile: info.secondaryMobile
      }

      if (this.personalInfo.dateOfBirth && this.personalInfo.name && this.personalInfo.email.value) {
        this.isPersonalProfileComplete = true
      }
    },
    dateOfBirthMenu(val) {
      val && setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = 'YEAR'))
    },
    updateDetailResponseComplete() {
      let response = this.$store.state.member.updateDetailResponse
      if (response.complete) {
        this.updateDetailResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_DETAIL_STORE}`)
      }
    },
    smsVerificationSent() {
      let errorCode = this.$store.state.member.smsVerificationSent.code
      if (errorCode.code != 0) {
        //show err msg
        let errMsg = ''
        if (errorCode == -1) {
          errMsg = locale.getMessage('fieldErrorMessage.invalidCaptchaCode')
        } else if (errorCode == -3) {
          errMsg = locale.getMessage('fieldErrorMessage.tooManyRequest')
        } else {
          errMsg = errorCodeHelper.getErrorCodeDesc(errorCode)
        }
        this.sendOTPAPIResult = errMsg
        this.otpResultDialogShow = true
      }
    }
  },
  methods: {
    closeAddNumberDialog() {
      this.addNewNumberDialog = false
    },
    async AddNewSubContact() {
      if (this.$refs.addNumberForm.validate()) {
        let filter = {
          verification_code: '',
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    async verifyAndAddNewContact() {
      if (this.$refs.otpForm.validate()) {
        let filter = {
          verification_code: this.verificationCode,
          mobileNumber: this.newContactNumber.mobile,
          required_only_primary_verified: true
        }

        this.$store.dispatch(`${MEMBER_ADD_NEW_CONTACT}`, {
          filter
        })
      }
    },
    verifyPrimaryNumber() {
      this.phonePriority = 0
      this.newContactNumber.is_new_number = false
      this.newContactNumber.mobile = this.memberInfo.mobileList[0].value
      this.otpDialogShow = true
      this.is_add_new_number = true
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    closeOtpResultDialog() {
      this.otpResultDialogShow = false
    },
    async verifySmsCode() {
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value

      let registerObj = {
        verificationCode: this.verificationCode,
        mobileNumber: verifyPhoneNumber,
        captchaId: this.$refs.captchaCode.$refs.code.id,
        captchaCode: this.captcha
      }
      this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
        registerObj
      })
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      let verifyPhoneNumber = this.memberInfo.mobileList[0].value
      //if (this.$refs.registerForm2.validate()) {
      if (this.captcha != '') {
        let registerObj = {}
        registerObj.domain = uiHelper.getHostname()
        registerObj.language = uiHelper.getLanguage()
        registerObj.currency = this.personalInfo.currency
        registerObj.memberCode = this.personalInfo.memberCode
        // registerObj.mobileNumber = this.phonePriority == 1 ? this.personalInfo.mobile.value : this.phonePriority == 2 ? this.personalInfo.secondaryMobile.value : this.personalInfo.mobile.value
        registerObj.mobileNumber = verifyPhoneNumber
        registerObj.request_otp = true
        registerObj.captchaId = this.$refs.captchaCode.$refs.code.id
        registerObj.captchaCode = this.captcha

        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
        this.showText = true
      }
      //}
    },
    openOTPDialog(_phonePriority) {
      this.phonePriority = _phonePriority
      let arrIndex = this.phonePriority
      const mobileList = this.memberInfo.mobileList
      if (Array.isArray(mobileList) && mobileList.length > arrIndex) {
        this.newContactNumber.mobile = mobileList[arrIndex].value
      }
      this.otpDialogShow = true
    },
    closeOTPDialog() {
      this.otpDialogShow = false
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
    },
    openEditDialog() {
      this.editDialogShow = true
    },
    closeEditDialog() {
      this.editDialogShow = false
    },
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getCountry() {
      let countryLookupObj = {
        language: uiHelper.getCookie('language') + '-cn'
      }
      this.$store.dispatch(`${LOOKUP_COUNTRY}`, { countryLookupObj })
    },
    getCountryProvince(data) {
      let provinceLookupObj = {
        language: uiHelper.getCookie('language') + '-cn',
        countryCode: data
      }
      this.$store.dispatch(`${LOOKUP_PROVINCE}`, { provinceLookupObj })
    },
    countryDisplayText(data) {
      return data.displayText
    },
    provinceDisplayText(data) {
      return data.displayText
    },
    updatePersonalInfo() {
      if (this.$refs.profileForm.validate()) {
        let memberObj = this.personalInfoObj

        let countryLocalname = this.country.find(x => x.value == memberObj.country).displayText
        let nationalityLocalname = this.country.find(x => x.value == memberObj.nationality).displayText
        memberObj.country = countryLocalname
        memberObj.nationality = nationalityLocalname
        this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, { memberObj })
      }
    },
    updateDetailResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberPersonalDetailUpdated`))
      }
    },
    openLiveChat() {
      this.$parent.$parent.openLiveChat()
    }
  }
}
</script>
<style lang="scss">
.SubPhoneCSS {
  padding-left: 48px !important;
  padding-right: 32px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.otpSubmitButton {
  text-transform: capitalize;
  border-radius: 10px;
}

.otp-button {
}

.mobile-subtitle {
  font-size: 0.8rem !important;
  font-weight: normal;
}

.otp-text {
  font-size: 10px !important;
}

.phoneNo {
  display: flex;
  align-items: flex-end;
}

.phoneVerificationStatus {
  display: flex;
  padding-left: 2em;
  padding-right: 0.5em;
}

.verifyOTPTxt {
  color: #f88717 !important;
  text-decoration: underline;
  cursor: pointer;
}

.verifiedTxt {
  color: #f88717 !important;
}

.profile-live-chat {
  color: #f88717 !important;
  text-decoration: underline;
  cursor: pointer;
}

.v-input__icon--append .v-icon {
  color: #2ba500;
}

@media (max-width: 959px) {
  .profile-page {
    hr {
      display: none;
    }

    .v-card {
      box-shadow: none;
    }
  }
  .page-title-container {
    display: none;
  }
}
</style>
